import { BiMenu } from "react-icons/bi";
import Logo from "../../images/Logo.png";
import { useContextHook } from "../../Context/ContextOPen";

import { useState } from "react";
import { Link } from "react-router-dom";
import { usePOST } from "../../APIs/useMyAPI";
import ButtonRed from "../Buttons/ButtonRed";

const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  const { handleSubmit } = usePOST({});
  const handleFormSubmit = (event) => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <div
        onClick={() => setSure(false)}
        className={`${
          sure ? "" : "hidden"
        } fixed w-full  h-full top-0 left-0 popup z-50 flex justify-center items-center`}
      >
        <div className="bg-white z-[60] rounded-3xl w-[500px] max-w-[500px] min-h-[200px]">
          <p className="font-semibold text-3xl text-center py-7">
            Are you sure to log out?
          </p>
          <div className="flex items-end m-5">
            <Link to="/login" onClick={""}>
              <ButtonRed
                className="py-3 px-10"
                name="Yes"
                onClick={handleFormSubmit}
              />
            </Link>
            <button
              onClick={() => setSure(false)}
              className=" border px-10 border-Brown text-Brown bg-white font-semibold  p-3 rounded-xl ml-5"
            >
              No
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto sticky top-0 z-40 bg-white flex items-center justify-between flex-wrap mb-5 pb-2 border-b-2 border-Brown">
        <div>
          <BiMenu
            size={35}
            className="text-Brown cursor-pointer"
            onClick={changeMenu}
          />
        </div>
        <div className="">
          <img src={Logo} alt="" className="w-24 pt-2" />
        </div>
        <div className="flex gap-9 mx-3">
          <ButtonRed
            className="px-7 py-3"
            onClick={() => setSure(true)}
            name="Log Out"
          />
        </div>
      </div>
    </>
  );
};

export default Navbar;
