import { Col, Row } from "../../Grid-system";
import { ButtonRed, CardBox, Title } from "../../components";

function Sell() {
  return (
    <div>
      <Title title="Sell Your Property With Nawy" />
      <Row>
        <Col xs={6}>
          <CardBox showEdit={true} edit={`/sell/SellEdit`}>
            <h2 className="text-center text-2xl font-semibold text-red-600 mb-4">
              User Information
            </h2>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Name:
              </span>
              <span> icr</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Location:
              </span>
              <span> Maadi, Cairo Governorate, Egypt</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Address:
              </span>
              <span> hamra</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Phone:
              </span>
              <span> 0987654321</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Property Type:
              </span>
              <span> vila</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Description:
              </span>
              <span className="">
                We are not the only ones but we are the best
              </span>
            </div>
          </CardBox>
        </Col>
      </Row>
      {/* <ButtonRed
        name="Add New jop"
        className="w-fit ml-auto px-5 py-2"
        link="/sell/SellEdit"
      /> */}
    </div>
  );
}
export default Sell;
