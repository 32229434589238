import { Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useFilter } from "../../APIs/useMyAPI";

const Income = () => {
  const { filter, setFilter } = useFilter();
  return (
    <div>
      <Title title="Income" />
      <Row justify="end">
        {filter.get("filter") === "ch1" && (
          <Row>
            <Col md={2}>
              <input
                type="search"
                placeholder="search"
                className="w-full rounded-xl border-2 p-2 border-black"
              />
            </Col>
            <Col md={8} className={`flex gap-5`}>
              <div className=" border border-Blue p-2 rounded-xl relative flex items-center justify-center">
                <span className="absolute -top-3 bg-white px-2 left-3">
                  End Date
                </span>
                <input type="date" />
              </div>
              <div className=" border border-Blue p-2 rounded-xl relative flex items-center justify-center">
                <span className="absolute -top-3 bg-white px-2 left-3">
                  Start Date
                </span>
                <input type="date" />
              </div>
            </Col>
          </Row>
        )}
        {filter.get("filter") === "ch2" && (
          <Row>
            <Col md={2}>
              <input
                type="search"
                placeholder="search"
                className="w-full rounded-xl border-2 p-2 border-black"
              />
            </Col>
            <Col md={3} className={`flex gap-5 `}>
              <div className=" border border-Blue p-2 rounded-xl relative flex items-center justify-center">
                <span className="absolute -top-3 bg-white px-2 left-3">
                  Date
                </span>
                <input type="date" />
              </div>
            </Col>
          </Row>
        )}
        <Col md={2}>
          <select
            onChange={(e) => setFilter({ filter: e.target.value })}
            className="border border-Blue px-4 py-2 mb-4 rounded-lg"
          >
            <option value="all">الكل</option>
            <option value="">اليوم</option>
            <option value="">الاسبوع</option>
            <option value="">الشهر</option>
            <option value="ch2">يوم محدد</option>
            <option value="ch1"> (من-الى) تاريخ</option>
          </select>
        </Col>
      </Row>
      <div className="w-1/2 h-[360px] max-sm:w-full mx-auto flex flex-col  bgimg border-2 border-Blue rounded-2xl p-10 ">
        <h1 className="text-2xl font-semibold mb-10">Total Income</h1>
        <div className="border border-Blue h-full rounded-2xl bg-white flex items-center justify-center">
          <h1>123009,99000$</h1>
        </div>
      </div>
    </div>
  );
};

export default Income;
