import { Col, Row } from "../../Grid-system";
import { ButtonRed, CardBox, Title } from "../../components";
import image from "../../images/pubg-battlegrounds-offer-nyz0s.png";
const ImageSlider = () => {
  return (
    <>
      <Title title="Image Slider" />
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
      </Row>
      <ButtonRed
        name="Add Image"
        className="w-fit ml-auto px-5 py-2"
        link="/home/Edit"
      />
      </>
  );
};

export default ImageSlider;
