function Input(props) {
  return (
    <div>
      <input
        type="text"
        placeholder={props.title}
        className="border border-Blue m-1 py-3 rounded-xl"
      />
    </div>
  );
}
export default Input