import { useFilter } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { CardBox } from "../../components";

function HowItWorks() {
  const { filter, setFilter } = useFilter();
  return (
    <div>
      <div
        className="flex justify-center items-center gap-10 my-5"
        onChange={(e) => setFilter({ filter: e.target.value })}
      >
        <div className="flex justify-center items-center gap-2">
          <input
            type="checkbox"
            value="buyer"
            className="w-5 h-5"
          />
          <p className="text-xl font-bold">Buyer</p>
        </div>
        <div className="flex justify-center items-center gap-2">
          <input
            type="checkbox"
            value="seller"
            className="w-5 h-5"
          />
          <p className="text-xl font-bold">Seller</p>
        </div>
      </div>
      {filter.get("filter") === "buyer" && (
        <Row className="justify-center">
          <Col lg={9}>
            <CardBox edit="/howItWorks/buyerEdit">
              <h1 className="text-4xl text-center font-bold my-4">Buyer</h1>
              <div className="max-sm:w-full mx-auto bg-slate-100 p-5 rounded-3xl">
                <h1 className="text-2xl font-bold my-4">Find Your New Home</h1>
                <p className="text-xl">
                  Explore compounds to find a property that meets your
                  requirements.
                </p>
                <h1 className="text-2xl font-bold my-4">
                  Contact Our Property Consultants
                </h1>
                <p className="text-xl">
                  Get expert advice from our property consultants to make the
                  right choice.
                </p>
                <h1 className="text-2xl font-bold my-4">Buy Your New Home</h1>
                <p className="text-xl">
                  Our team will facilitate the transaction to ensure a seamless
                  experience.
                </p>
              </div>
            </CardBox>
          </Col>
          {/* <div className="flex justify-end my-3">
            <Add
              link="/aboutUs/editDescription"
              name="Edit"
              className="px-7 py-2"
            />
          </div> */}
        </Row>
      )}
      {filter.get("filter") === "seller" && (
        <Row className="justify-center">
          <Col lg={9}>
            <CardBox edit="/howItWorks/SellerEdit">
              <h1 className="text-4xl text-center font-bold my-4">Seller</h1>
              <div className="max-sm:w-full mx-auto bg-slate-100 p-5 rounded-3xl">
                <h1 className="text-2xl font-bold my-4">Find Your New Home</h1>
                <p className="text-xl">
                  Explore compounds to find a property that meets your
                  requirements.
                </p>
                <h1 className="text-2xl font-bold my-4">
                  Contact Our Property Consultants
                </h1>
                <p className="text-xl">
                  Get expert advice from our property consultants to make the
                  right choice.
                </p>
                <h1 className="text-2xl font-bold my-4">Buy Your New Home</h1>
                <p className="text-xl">
                  Our team will facilitate the transaction to ensure a seamless
                  experience.
                </p>
              </div>
            </CardBox>
          </Col>
        </Row>
      )}
    </div>
  );
}
export default HowItWorks;
