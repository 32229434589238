import { BiSave } from "react-icons/bi";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Col, Row } from "../../Grid-system";
import { Back } from "../../components";
import { BsWhatsapp } from "react-icons/bs";

const UpditeContact = () => {
  return (
    <>
      <Row justify="center" gap={5}>
        <Col
          md={5}
          className=" border-4 border-Brown rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <input
            type="text"
            name="text"
            placeholder="Address"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              // onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
        <Col
          md={5}
          className=" border-4 border-Brown rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <input
            type="text"
            name="text"
            placeholder="phone"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              // onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
        <Col
          md={5}
          className=" border-4 border-Brown rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <input
            type="text"
            name="text"
            placeholder="e-mail"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              // onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>

        <Col
          md={5}
          className=" border-4 border-Brown rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <input
            type="text"
            name="text"
            placeholder="working hours"
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              // onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
        <Col
          md={5}
          className=" border-4 border-Brown rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <FaInstagram size={60} color="#CB3878" />
          <input
            type="text"
            name="text"
            placeholder="https://url...."
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              // onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
        <Col
          md={5}
          className=" border-4 border-Brown rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <FaFacebook size={60} color="#3B5998" />
          <input
            type="text"
            name="text"
            placeholder="https://url...."
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              // onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>

        <Col
          md={5}
          className=" border-4 border-Brown rounded-xl flex flex-between p-4 items-center relative bg-white"
        >
          <BsWhatsapp size={60} color="#1DCD2A" />
          <input
            type="text"
            name="text"
            placeholder="https://url...."
            className="text-Gray text-xl font-semibold px-2 outline-none py-5 w-[100%]"
          />
          <div className=" ">
            <BiSave
              // onClick={() => post("instgram", formData.instgram)}
              color="#3B5998 "
              className="mx-1 cursor-pointer"
              size={35}
            />
          </div>
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default UpditeContact;
