import React from "react";
import ButtonRed from "./ButtonRed";

const Back = () => {
  return (
    <div className="fixed bottom-10 right-10 flex flex-col gap-2">
      <ButtonRed
        className="py-2 px-5"
        name="Back"
        onClick={() => window.history.go(-1)}
      />
    </div>
  );
};

export default Back;
