import { Input, TextArea } from "../../../components";

function NewEdit() {
  return (
    <div>
      <Input title="name" />
      <Input title="الاسم" />
      <TextArea title="Description" />
      <TextArea title="الوصف" />
    </div>
  );
}
export default NewEdit