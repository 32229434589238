import { Col, Row } from "../../Grid-system";
import { ButtonRed, CardBox, DisplaySection, Title } from "../../components";
import image from "../../images/pubg-battlegrounds-offer-nyz0s.png";
import RealEstate from "./RealEstate/RealEstate";
import TrendingProjects from "./TrendingProjects/TrendingProjects";

function Launches() {
  return (
    <div>
      <Title title="Launching Soon" />
      <Row>
        <Col md={4}>
          <CardBox edit="/launches/editLaunches">
            <DisplaySection
              src={image}
              name="IVOIRE Zayed by PRE"
              text="Price Start From "
              history="7,900,000 EGP"
              display="true"
            />
          </CardBox>
        </Col>
        <div className="">
          <ButtonRed
            name="Add product"
            className="w-fit ml-auto px-5 py-2"
            link="/launches/editLaunches"
          />
        </div>
      </Row>
      <TrendingProjects />
      <RealEstate />
    </div>
  );
}
export default Launches;
