import React from "react";
import { usePOST } from "../../APIs/useMyAPI";
import { AddImage, Back, ButtonRed, Input, TextArea } from "../../components";

const EditAboutUs = () => {
  const {
    handleChange,
    handleSubmit,
    img,
    setImg,
    imgs,
    setImgs,
    imgss,
    setFormData,
    formData,
  } = usePOST();
  return (
    <div>
      <Input title="name" />
      <Input title="الاسم" />
      <TextArea title="Description" />
      <TextArea title="الوصف" />
      <AddImage
        id="image"
        name="images"
        onChange={handleChange}
        // img={test}
        title="add image about"
        newimg={img}
        clickDelete={() => {
          setImg("");
          setFormData({ ...formData, images: "" });
        }}
      />
      <div className="flex justify-end">
        <ButtonRed name="save" className="py-2 px-5" />
      </div>
      <Back />
    </div>
  );
};

export default EditAboutUs;
