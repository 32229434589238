import { Link } from "react-router-dom";

function SmallSection(props) {
  return (
    <div className="p-3">
      <Link to={props.link}>
        <div className="overflow-hidden rounded-[30px] text-center bg-white border space-y-2">
          <img
            src={props.src}
            alt=""
            className="w-full h-[200px] max-md:h-[130px]"
          />
          <div className="space-y-2 px-2">
            <h2 className="font-bold text-xl">{props.name}</h2>
            <p className="text-Light font-semibold text-lg">{props.title}</p>
            <p className="font-lg text-lg">{props.compounds}</p>
            <p className="font-lg text-lg pb-2">{props.propertiesAvailable}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default SmallSection;
