function TextArea(props) {
  return (
    <div>
      <textarea
        type="text"
        placeholder={props.title}
        className="border border-Blue outline-none m-1 py-3 rounded-xl block w-full px-3 h-[150px] "
      />
    </div>
  );
}
export default TextArea