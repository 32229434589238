import { Col, Row } from "../../../Grid-system";
import { Back, ButtonRed, Input } from "../../../components";

function JobOpeningsEdit() {
  return (
    <div>
      <Row justify={"center"}>
        <Col md={7} className="">
          <Row justify="center">
            <Col>
              <Input title="Job Title 1" />
              <Input title="Job Title 2" />
              <Input title="Location" />
              <Input title="Work type" />
            </Col>
            <Col col={6}>
              <ButtonRed name="Add" className="h-full px-4 py-3" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
}
export default JobOpeningsEdit;
