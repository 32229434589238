import { Col, Row } from "../../Grid-system";
import { ButtonRed, CardBox, Title } from "../../components";
import image from "../../images/pubg-battlegrounds-offer-nyz0s.png";

function Design() {
  return (
    <div>
      <Title title="Design" />
      <Row>
        <div className="border border-Blue rounded-2xl h-full p-4 my-3">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consectetur
          consequatur alias odio quae doloremque ipsa quia deserunt. Sapiente
          impedit sed tempore soluta consequatur itaque, eaque qui earum rerum
          sequi natus. Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Consectetur consequatur alias odio quae doloremque ipsa quia deserunt.
          Sapiente impedit sed tempore soluta consequatur itaque, eaque qui
          earum rerum sequi natus. Lorem ipsum dolor sit, amet consectetur
          adipisicing elit. Consectetur consequatur alias odio quae doloremque
          ipsa quia deserunt. Sapiente impedit sed tempore soluta consequatur
          itaque, eaque qui earum rerum sequi natus. Lorem ipsum dolor sit, amet
          consectetur adipisicing elit. Consectetur consequatur alias odio quae
          doloremque ipsa quia deserunt. Sapiente impedit sed tempore soluta
          consequatur itaque, eaque qui earum rerum sequi natus.
        </div>
        <div className="w-fit flex justify-end my-3">
          <ButtonRed
            link="/design/editDescription"
            name="Edit"
            className="px-7 py-2"
          />
        </div>
      </Row>
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/home/Edit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
      </Row>
      <ButtonRed
        name="Add Image"
        className="w-fit ml-auto px-5 py-2"
        link="/design/Edit"
      />
    </div>
  );
}
export default Design;
