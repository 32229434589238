import { NavLink } from "react-router-dom";

function DisplaySection({ link, src, name, text, history, display }) {
  return (
    <div className="p-3">
      <NavLink to={link || ""}>
        <div
          className={`${
            display ? "flex-col" : ""
          } flex rounded-3xl bg-white overflow-hidden h-full`}
        >
          <img
            src={src}
            alt=""
            className={`${display ? "w-full h-[250px]" : "w-1/2"} `}
          />
          <div className="space-y-3 p-1 mx-2 py-3 my-auto">
            <h2 className="text-Blue font-bold text-xl">{name}</h2>
            <p className="text-lg">{text}</p>
            <p className="text-lg">{history}</p>
          </div>
        </div>
      </NavLink>
    </div>
  );
}
export default DisplaySection;
