import { Col, Row } from "../../Grid-system";
import { ButtonRed, CardBox, DisplaySection, Title } from "../../components";
import image from "../../images/pubg-battlegrounds-offer-nyz0s.png";

function Blogs() {
  return (
    <div>
      <Row>
        <Title title="Featured Articles" />
        <Col lg={4} md={6}>
          <CardBox edit="/blogs/editBlogs">
            <DisplaySection
              src={image}
              name="Cityscape"
              text="Catch The Unique Properties For Sale In New Capital City"
              history="Mar 20 - 5 min"
              display="true"
              link="/blogs/details"
            />
          </CardBox>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-5">
        <ButtonRed
          name="Add New Blogs"
          className="w-fit ml-auto px-5 py-2"
          link="/blogs/editBlogs"
        />
      </div>
    </div>
  );
}
export default Blogs;
