import { Link } from "react-router-dom";
import { Col, Row } from "../../../Grid-system";
import { CardBox, NormalPhoto } from "../../../components";
import text from "../../../images/pubg-battlegrounds-offer-nyz0s.png";

function New() {
  return (
    <div>
      <Row className="border border-Blue rounded-2xl h-full p-4">
        <Col md={4} xs={6}>
          <CardBox edit="/aboutUs/New/NewEdit">
            <NormalPhoto
              src={text}
              name="Egypt's Proptech Startup Nawy Secures $5M In Seed Round Led By Sawiris Family Office"
              title="Egyptian Property Technology (PropTech) Startup Nawy Said It Successfully Closed A $5 Million Seed Funding Round Led By The Sawiris Family Office, According To A Statement Released On Friday.
                Read More In Forbes ME"
            />
          </CardBox>
        </Col>
        <Link to="/aboutUs/New/NewEdit" className="flex justify-end">
          <div
            className={`flex z-10 justify-center font-bold text-5xl text-white cursor-pointer w-14 h-14 rounded-full bg-Blue`}
          >
            +
          </div>
        </Link>
      </Row>
    </div>
  );
}
export default New