function NormalPhoto(props) {
  return (
    <div className="bg-white p-2 rounded-3xl overflow-hidden space-y-3">
      <img src={props.src} alt="" className="rounded-3xl px-2 my-2" />
      <h2 className="text-center text-Blue font-bold text-xl leading-8">
        {props.name}
      </h2>
      <p className="text-center text-lg font-medium leading-8">
        {props.title}
      </p>
    </div>
  );
}
export default NormalPhoto;
