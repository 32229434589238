import { Col, Row } from "../../../Grid-system";
import { ButtonRed, CardBox, Title } from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";

function OurPeople() {
  return (
    <div>
      <Title title="Our People" />
      <Row>
        <Col lg={4} xs={6}>
          <CardBox edit={`/Careers/OurPeopleEdit`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
            <h2 className="text-center text-xl text-Blue font-semibold">
              Mohamed Shoukry Senior Sales Manager
            </h2>
            <p>
              “ In 2019, my journey started with Cooing as a Property Consultant
              and I have always felt it's different over here because it was
              never only about making numbers or reaching the target as much as
              achieving our clients' satisfaction.”
            </p>
          </CardBox>
        </Col>
      </Row>
      <ButtonRed
        name="Add New Person"
        className="w-fit ml-auto px-5 py-2"
        link="/Careers/OurPeopleEdit"
      />
    </div>
  );
}
export default OurPeople;
