import React from "react";
import { Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useParams } from "react-router-dom";

const AddUser = () => {
  const { id } = useParams();
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Purple rounded-2xl p-10 ">
            <Title title="Add User" className="mb-14 mt-5" />
            <input
              type="text"
              name=""
              placeholder="User Name"
              className="border py-4 rounded-xl mb-4 "
            />
            <input
              type="text"
              name=""
              placeholder="E-mail (Optional)"
              className="border py-4 rounded-xl mb-4 "
            />
            <input
              type="text"
              name=""
              placeholder="Phone Number"
              className="border py-4 rounded-xl mb-4 "
            />
            <input
              type="text"
              name=""
              placeholder="Password"
              className="border py-4 rounded-xl mb-4 "
            />
            {id === "id" ? (
              <div className="flex  items-center gap-10 justify-center my-3">
                <div className="flex  items-center  gap-2">
                  <input type="radio" name="typeUser"/> <h1>Company</h1>
                </div>
                <div className="flex  items-center  gap-2">
                  <input type="radio" name="typeUser"/> <h1>User</h1>
                </div>
              </div>
            ) : (
              ""
            )}
            <input
              type="submit"
              name=""
              className="border py-4 rounded-xl mb-4 text-white cursor-pointer bg-gradient-to-l to-Pink from-Purple "
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddUser;
