import { Col, Row } from "../../../Grid-system";
import { Back, ButtonRed, Input } from "../../../components";

function TopCompoundDetails() {
  return (
    <div>
      <Row justify={"center"}>
        <Col md={7} className="">
          <Row justify="center">
            <Col>
              <Input title="title" />
              <Input title="first price" />
              <Input title="last price" />
              <Input title="location" />
            </Col>
            <Col col={6}>
              <ButtonRed name="Add" className="h-full px-4 py-3" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
}
export default TopCompoundDetails;
