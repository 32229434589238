import {
  AddUser,
  ChangePassword,
  ContactUs,
  EditHome,
  Home,
  Login,
  Message,
  OrdersUser,
  UpditeContact,
  AboutUs,
  Users,
  EditAboutUs,
  Income,
  Order,
  Orders,
  EditAboutUsDescription,
  New,
  NewEdit,
  Compounds,
  Areas,
  EditArea,
  TopAreaEdit,
  TopArea,
  TopAreaDetails,
  TopAreaDetailsEdit,
  EditCompounds,
  TopCompoundsEdit,
  TopCompounds,
  Buy,
  Sell,
  Rent,
  NewProjects,
  IncomeProperty,
  Design,
  Blogs,
  Careers,
  DesignEdit,
  DesignEditDescription,
  ProductSlider,
  TopCompoundDetails,
  IconCompound,
  TopCompoundsDetails,
  TopCompoundsDetailsEdit,
  EditBuy,
  EditSell,
  EditRent,
  EditNewProjects,
  EditIncomeProperty,
  EditBlogs,
  EditCareers,
  EditBlogDetails,
  BlogDetails,
  JobOpeningsEdit,
  OurPeopleEdit,
  OurBenefitsEdit,
  HowItWorks,
  TermsOfUse,
  TermsOfUseEdit,
  Launches,
  LaunchesEdit,
  TrendingProjectsEdit,
  RealEstateEdit,
  SellerEdit,
  BuyerEdit,
} from "./pages/index";
import { Navbar, SideBar } from "./components/index";
import { Route, Routes } from "react-router-dom";
import Logo from "./images/Logo.png";
import { RequireAuth } from "./APIs/useMyAPI";
import { Container } from "./Grid-system";

const App = () => {
  // const { data } = useFETCH("admin/auth/check");
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<Login />} />
              {/* <Route element={<RequireAuth />}> */}
                <Route
                  path=""
                  element={
                    <h1 className="grid place-content-center h-[80vh]">
                      <img src={Logo} alt="" className="w-[250px]" />
                    </h1>
                  }
                />
                <Route
                  path="*"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      404 | no page found
                    </h1>
                  }
                />
                <Route
                  path="403"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      403 | FORBIDDEN
                    </h1>
                  }
                />
                <Route
                  path="500"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      500 | Internal Server Error
                    </h1>
                  }
                />
                <Route path="home">
                  <Route index element={<Home />} />
                  <Route path="Edit" element={<EditHome />} />
                </Route>
                <Route path="aboutUs">
                  <Route index element={<AboutUs />} />
                  <Route
                    path="editDescription"
                    element={<EditAboutUsDescription />}
                  />
                  <Route path="Edit" element={<EditAboutUs />} />
                  <Route path="New" element={<New />} />
                  <Route path="New/NewEdit" element={<NewEdit />} />
                </Route>
                <Route path="Compounds">
                  <Route index element={<Compounds />} />
                  <Route path="editCompounds" element={<EditCompounds />} />
                  <Route path="topCompounds" element={<TopCompounds />} />
                  <Route
                    path="topCompounds/editCompounds"
                    element={<TopCompoundsEdit />}
                  />
                  <Route
                    path="topCompounds/topCompoundsDetails"
                    element={<TopCompoundsDetails />}
                  />
                  <Route
                    path="topCompounds/topCompoundsDetails/topEditCompoundsDetails"
                    element={<TopCompoundsDetailsEdit />}
                  />
                  <Route
                    path="topCompounds/topCompoundsDetails"
                    element={<TopCompoundsDetails />}
                  />
                  <Route
                    path="topCompounds/topCompoundsDetails/ProductSliderEdit"
                    element={<ProductSlider />}
                  />
                  <Route
                    path="topCompounds/topCompoundsDetails/DetailsEdit"
                    element={<TopCompoundDetails />}
                  />
                  <Route
                    path="topCompounds/topCompoundsDetails/IconCompoundEdit"
                    element={<IconCompound />}
                  />
                </Route>
                <Route path="area">
                  <Route index element={<Areas />} />
                  <Route path="editArea" element={<EditArea />} />
                  <Route path="topArea" element={<TopArea />} />
                  <Route path="topArea/editArea" element={<TopAreaEdit />} />
                  <Route
                    path="topArea/topAreaDetails"
                    element={<TopAreaDetails />}
                  />
                  <Route
                    path="topArea/topAreaDetails/topEditAreaDetails"
                    element={<TopAreaDetailsEdit />}
                  />
                </Route>
                <Route path="buy">
                  <Route index element={<Buy />} />
                  <Route path="editBuy" element={<EditBuy />} />
                </Route>
                <Route path="sell">
                  <Route index element={<Sell />} />
                  <Route path="editSell" element={<EditSell />} />
                </Route>
                <Route path="rent">
                  <Route index element={<Rent />} />
                  <Route path="editRent" element={<EditRent />} />
                </Route>
                <Route path="newProjects">
                  <Route index element={<NewProjects />} />
                  <Route path="editNewProjects" element={<EditNewProjects />} />
                </Route>
                <Route path="howItWorks">
                  <Route index element={<HowItWorks />} />
                  <Route path="buyerEdit" element={<BuyerEdit />} />
                  <Route path="sellerEdit" element={<SellerEdit />} />
                </Route>
                <Route path="termsOfUse">
                  <Route index element={<TermsOfUse />} />
                  <Route path="editTermsOfUse" element={<TermsOfUseEdit />} />
                </Route>
                <Route path="launches">
                  <Route index element={<Launches />} />
                  <Route path="editLaunches" element={<LaunchesEdit />} />
                  <Route
                    path="editTrendingProjectsEdit"
                    element={<TrendingProjectsEdit />}
                  />
                  <Route
                    path="editRealEstateEdit"
                    element={<RealEstateEdit />}
                  />
                </Route>
                <Route path="incomeProperty">
                  <Route index element={<IncomeProperty />} />
                  <Route
                    path="editIncomeProperty"
                    element={<EditIncomeProperty />}
                  />
                </Route>
                <Route path="design">
                  <Route index element={<Design />} />
                  <Route path="Edit" element={<DesignEdit />} />
                  <Route
                    path="editDescription"
                    element={<DesignEditDescription />}
                  />
                </Route>
                <Route path="blogs">
                  <Route index element={<Blogs />} />
                  <Route path="editBlogs" element={<EditBlogs />} />
                  <Route path="details" element={<BlogDetails />} />
                  <Route path="details/edit" element={<EditBlogDetails />} />
                </Route>
                <Route path="careers">
                  <Route index element={<Careers />} />
                  <Route path="editCareers" element={<EditCareers />} />
                  <Route path="JobOpeningsEdit" element={<JobOpeningsEdit />} />
                  <Route path="OurPeopleEdit" element={<OurPeopleEdit />} />
                  <Route path="OurBenefitsEdit" element={<OurBenefitsEdit />} />
                </Route>
                <Route path="message" element={<Message />} />
                <Route path="users">
                  <Route index element={<Users />} />
                  <Route path=":id" element={<AddUser />} />
                  <Route path="Orders-user/:id" element={<OrdersUser />} />
                </Route>
                <Route path="income" element={<Income />} />
                <Route path="Contact-Us">
                  <Route index element={<ContactUs />} />
                  <Route path="edit" element={<UpditeContact />} />
                </Route>
                <Route path="Orders">
                  <Route index element={<Orders />} />
                  <Route path=":id" element={<Order />} />
                </Route>
                <Route path="Change-Password" element={<ChangePassword />} />
              {/* </Route> */}
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
