import { Link } from "react-router-dom";
import { Col, Row } from "../../../Grid-system";
import { ButtonRed, CardBox, Product, SmallSection, Title } from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";
const TopAreaDea = () => {
  return (
    <>
      <Title title="Top Area" />
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/area/topArea/topAreaDetails/topEditAreaDetails`}>
            {/* <Link to="/area/topArea/topAreaDetails/details"> */}
            <Product
              src={image}
              title="Apartment -sodic east"
              bed="3"
              bath="3"
              distance="360 m"
              price="108,534 Monthly / 8 years"
              priceEGP="11,577,000 | EGP"
            />
            {/* </Link> */}
          </CardBox>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-5">
        <ButtonRed
          name="Add New Top Area Details"
          className="w-fit ml-auto px-5 py-2"
          link="/area/topArea/topAreaDetails/topEditAreaDetails"
        />
      </div>
    </>
  );
};

export default TopAreaDea;
