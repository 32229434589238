import { Link } from "react-router-dom";
import { Col, Row } from "../../../Grid-system";
import { ButtonRed, CardBox, SmallSection, Title } from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";
const TopCompounds = () => {
  return (
    <>
      <Title title="Top Compounds" />
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/compounds/topCompounds/editCompounds`}>
            <Link to="/">
              <SmallSection
                src={image}
                link="/compounds/TopCompounds/TopCompoundsDetails"
                name="6th of October City"
                compounds="130 compounds"
                propertiesAvailable="551 properties available"
              />
            </Link>
          </CardBox>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-5">
        <ButtonRed
          name="Add New Top compounds"
          className="w-fit ml-auto px-5 py-2"
          link="/compounds/topCompounds/editCompounds"
        />
      </div>
    </>
  );
};

export default TopCompounds;
