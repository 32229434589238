import React from "react";
import { Col, Row } from "../../Grid-system";
import test from "../../images/pubg-battlegrounds-offer-nyz0s.png";
import { DeleteTable, Title } from "../../components";
const Order = () => {
  return (
    <section className="py-4">
      <Title title="Order" />
      <Row
        justify="center"
        className="border border-boldBlue rounded-2xl p-5 mb-5"
      >
        <div className="flex justify-end">
          <DeleteTable />
        </div>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={6}
          className="border-4 border-Blue rounded-2xl p-5 m-3"
        >
          <img src={test} alt="" className="rounded-2xl" />
          <h1 className="text-xl font-semibold text-center">apple</h1>
          <p>new product available with different colors</p>
          <p className="mx-2 pb-1 font-semibold text-gray-500 ">
            Old price :
            <span className="line-through decoration-red-600">450$</span>
          </p>
          <p className="mx-2 pb-3 font-semibold">Price : 300$</p>
        </Col>
      </Row>
      <Row
        justify="center"
        className="border border-boldBlue rounded-2xl p-5 mb-5"
      >
        <Col md={4}>
          <div className=" max-md:w-full">
            <h2 className="text-xl font-bold text-Blue mb-3">معلومات الطلب</h2>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">ID : </div>
              <div>#5425452</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">اسم : </div>
              <div>Rama Rama</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">اسم المستخدم : </div>
              <div>Rama Rama</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">Phone : </div>
              <div> 5698654654</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">حالة الدفع :</div>
              <div className="text-green-600 font-semibold  ">تم</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">السعر الكلي:</div>
              <div>500$</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">التاريخ : </div>
              <div>2000/22/2 </div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">الوقت : </div>
              <div>10:00 AM </div>
            </div>
            <div>
              <img src={test} alt="" className="rounded-full w-20 h-20" />
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div className=" max-md:w-full">
            <h2 className="text-xl font-bold text-Blue mb-3">
              معلومات التوصيل
            </h2>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">الاسم :</div>
              <div>Ali </div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">الرقم : </div>
              <div> 5698654654</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">مكان التوصيل : </div>
              <div> برامكة</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">ملاحظة أخرى:</div>
              <div className="">بطاطا</div>
            </div>
            <div className="flex gap-2 mb-2">
              <div className="font-semibold text-boldBlue">طريقة الدفع : </div>
              <div>Pay Pall </div>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Order;
