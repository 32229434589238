import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";

import { ButtonRed, Title } from "../../components";
import { Col, Row } from "../../Grid-system";

const ContactUs = () => {
  // const { data, isLoading } = useFETCH(`site-info/media`);
  return (
    <div>
      <Title title="Contact Us " />
      <Row gap={5} justify="center">
        <div>
          <h2 className="text-3xl text-gray-600 font-bold mb-4">
            Need expert advice?
          </h2>
          <p className="text-xl">
            Fill out the form and one of our property consultants will contact
            you.
          </p>
        </div>
        <div>
          <h3 className="text-red-600 font-bold text-xl">Address</h3>
          <p className="text-lg text-gray-600">
            47, North 90 Street, New Cairo, Egypt. Tower 2, Al Guezira Plaza, 6
            of October, Egypt
          </p>
        </div>
        <div>
          <h3 className="text-red-600 font-bold text-xl">Contacts</h3>
          <p className="text-lg text-gray-600">+201065888849</p>
          <p className="text-lg text-gray-600">info@nawy.com</p>
        </div>
        <div>
          <h3 className="text-red-600 font-bold text-xl">Working Hours</h3>
          <p className="text-lg text-gray-600">
            Sunday-Thursday 10:00 AM - 5:00 PM
          </p>
        </div>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <FaInstagram size={60} className="" color="#CB3878" />
          <p className="font-semibold px-2 text-Gray text-xl">00000000 </p>
        </Col>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <FaFacebook size={60} className="" color="#3B5998" />
          <p className="font-semibold px-2 text-Gray text-xl">00000000 </p>
        </Col>
        <Col
          md={5}
          className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Brown rounded-xl shadow-md font-semibold text-xl py-5 px-3 w-[48%] max-lg:w-[100%] bg-white"
        >
          <BsWhatsapp size={60} className="" color="#1DCD2A" />
          <p className="font-semibold px-2 text-Gray text-xl">00000000 </p>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-10">
        <ButtonRed link="/Contact-Us/edit" name="Edit" className="px-7 py-2" />
      </div>
    </div>
  );
};

export default ContactUs;
