import { Back, Editor } from "../../components"

function SellerEdit() {
  return (
    <div>
      <Editor />
      <Back />
    </div>
  );
}
export default SellerEdit