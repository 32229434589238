import accept from "../../images/Icon material-done.png";

const AcceptBtn = () => {
  return (
    <div className="w-[100px] mx-auto mb-1 py-2 px-2 border-2 rounded-xl font-semibold border-green-600 text-green-600 flex gap-2 items-center bg-white cursor-pointer">
      <span className="">Accept</span>
      <span>
        <img src={accept} alt="" width={15} />
      </span>
    </div>
  );
};

export default AcceptBtn;
