import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/Logo.png";

function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  return (
    <div
      className={`${openMenu ? "w-[360px]" : " w-[0px]"} ${
        openMenu
          ? " max-sm:translate-x-0 max-sm:w-[100%] "
          : " max-sm:-translate-x-full"
      } sidebar h-[100vh] bg-Blue max-sm:h-[100%] sticky max-sm:fixed left-0 top-0 transition-all overflow-y-scroll  py-10 z-40`}
    >
      <div
        className={`max-sm:block hidden text-end mx-4 text-xl font-semibold cursor-pointer`}
        onClick={changeMenu}
      >
        X
      </div>
      <div className="px-3">
        <ul className="text-white text-center font-semibold space-y-4 max-sm:mt-10">
          <li className="">
            <img
              src={logo}
              alt=""
              className="w-[200px] bg-white rounded-[20px] mx-auto"
            />
          </li>
          <li className=" ">
            <NavLink
              to={"/Home"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Home
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/compounds"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Compounds
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/area"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Areas
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/buy"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Buy
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/sell"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Sell
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/rent"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Rent
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/newProjects"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              New Projects
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/incomeProperty"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Income property
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/design"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Design
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/blogs"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Blogs
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/careers"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Careers
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/aboutUs"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              About Us
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/Users"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Users
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/Contact-Us"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Contact Us
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/Orders"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Orders
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/income"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Income
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/howItWorks"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              How It Works
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/termsOfUse"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Terms of use
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/launches"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Launches
            </NavLink>
          </li>
          <li className=" ">
            <NavLink
              to={"/Message"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Message
            </NavLink>
          </li>
          <li className="pb-10">
            <NavLink
              to={"/Change-Password"}
              className="text-xl py-2 block rounded-3xl hover:text-Brown hover:bg-white hover:text-[#2F2C8F]"
            >
              Change Password
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
