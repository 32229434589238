import React from "react";
import { CardBox, Title } from "../../components";
import { Col, Row } from "../../Grid-system";

const Message = () => {
  return (
    <>
      <Title title="Messages" />
      <Row>
        <Col lg={4} md={6}>
          <CardBox showEdit={true}>
            <div className="text-xl text-start pb-3 ">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Name :
              </span>
              <span> ali</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Preferred Location:
              </span>
              <span> london</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                country:
              </span>
              <span> syria</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Phone:
              </span>
              <span> 0992579620</span>
            </div>
            <div className="text-xl text-start pb-3">
              <span className="text-Brown font-bold text-xl pl-2 pr-3 text-Blue">
                Message :
              </span>
              <span>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Quaerat, consequatur?
              </span>
            </div>
          </CardBox>
        </Col>
      </Row>
    </>
  );
};

export default Message;
