import { Col, Row } from "../../../Grid-system";
import {
  ButtonRed,
  CardBox,
  Product,
  Title,
  Editor,
} from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";
import SliderAndDetails from "./SliderAndDetails";
const TopCompoundsDetails = () => {
  return (
    <>
      <SliderAndDetails />
      <Editor />
      <Title title="Top Compound details" />
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox
            edit={`/compounds/topCompounds/topCompoundsDetails/topEditCompoundsDetails`}
          >
            <Product
              src={image}
              title="Apartment -sodic east"
              bed="3"
              bath="3"
              distance="360 m"
              price="108,534 Monthly / 8 years"
              priceEGP="11,577,000 | EGP"
            />
          </CardBox>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-5">
        <ButtonRed
          name="Add New Top Area Details"
          className="w-fit ml-auto px-5 py-2"
          link="/compounds/topCompounds/topCompoundsDetails/topEditCompoundsDetails"
        />
      </div>
    </>
  );
};

export default TopCompoundsDetails;
