import { ButtonRed } from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";

function BlogDetails() {
  return (
    <div>
      <img src={image} alt="" className="rounded-3xl my-3" />
      <p>
        The New Capital City is the next step in real estate in Egypt, it
        contains everything from residential to commercial, administrative, and
        retail. As one of the fourth-generation Egyptian Cities, it includes a
        wide range of governmental buildings and facilities. On a mission to
        help you be a part of this world, this article will go over a wide array
        of properties for sale in New Capital City. Properties for sale in New
        Capital City Offered in different types, the properties for sale in New
        Capital City have something for everyone. Before directly delving into
        the properties for sale in New Capital City, let’s at the same time have
        a look at the compounds available with different themes and zoom in on
        the properties each compound include
      </p>
      <img src={image} alt="" className="rounded-3xl my-3" />
      <p>
        The New Capital City is the next step in real estate in Egypt, it
        contains everything from residential to commercial, administrative, and
        retail. As one of the fourth-generation Egyptian Cities, it includes a
        wide range of governmental buildings and facilities. On a mission to
        help you be a part of this world, this article will go over a wide array
        of properties for sale in New Capital City. Properties for sale in New
        Capital City Offered in different types, the properties for sale in New
        Capital City have something for everyone. Before directly delving into
        the properties for sale in New Capital City, let’s at the same time have
        a look at the compounds available with different themes and zoom in on
        the properties each compound include
      </p>
      <div className="fixed bottom-10 right-5">
        <ButtonRed
          name="Edit"
          className="w-fit ml-auto px-5 py-2"
          link="/blogs/details/edit"
        />
      </div>
    </div>
  );
}
export default BlogDetails;
