import { FaBath, FaBed, FaHeart, FaWhatsapp } from "react-icons/fa";
import product from "../../images/pubg-battlegrounds-offer-nyz0s.png";
import { MdOutlineListAlt, MdOutlineSocialDistance } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import NewProduct from "../../images/pubg-battlegrounds-offer-nyz0s.png";
function ProductCard(props) {
  return (
    <>
      <div
        className={
          "h-ful bg-white mx-1 relative my-2 p-2 rounded-3xl"
        }
      >
        {props.new ? (
          <img
            src={NewProduct}
            alt=""
            className="h-14 w-14 absolute top-5 left-5"
          />
        ) : (
          ""
        )}
        <img src={product} alt="" className="w-full h-1/2" />
        <div className="mx-3 mt-5">
          <div>
            <p className="text-xl font-bold mb-2">{props.title}</p>
            <p className="text-lg">New Helioplis Egypt</p>
            <div className="flex justify-around my-3">
              <div className="flex justify-center items-center gap-2">
                <FaBed color="#062371" size={25} />
                <p className="text-sm font-bold text-Blue">{props.bed}</p>
              </div>
              <div className="flex justify-center items-center gap-2">
                <FaBath color="#062371" size={25} />

                <p className="text-sm font-bold text-Blue">{props.bath}</p>
              </div>
              <div className="flex justify-center items-center gap-2">
                <MdOutlineSocialDistance color="#062371" size={25} />
                <p className="text-sm font-bold text-Blue">{props.distance}</p>
              </div>
            </div>
            <p className="text-sm font-semibold mb-2">{props.price}</p>
            <p className="text-sm pb-5">{props.priceEGP}</p>
          <div className="my-auto flex justify-center gap-1 items-center">
            <div className="flex justify-center items-center w-[35px] h-[35px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full ">
              <BsTelephone color="#062371" size={25} />
            </div>
            <div className="flex justify-center items-center w-[35px] h-[35px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full ">
              <FaWhatsapp color="#062371" size={25} />
            </div>
            <div className="flex justify-center items-center w-[35px] h-[35px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full ">
              <MdOutlineListAlt color="#062371" size={25} />
            </div>
            <div className="flex justify-center items-center w-[35px] h-[35px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full ">
              <FaHeart color="#062371" size={25} />
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductCard;
