import { Link } from "react-router-dom";
import { Col, Row } from "../../../Grid-system";
import { ButtonRed, CardBox, SmallSection, Title } from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";
import { Section } from "../../../components";
const TopArea = () => {
  return (
    <>
      <Title title="Top Area" />
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/area/topArea/editArea`}>
            <Link to="/">
              <SmallSection
                src={image}
                link="/area/topArea/topAreaDetails"
                name="6th of October City"
                compounds="130 compounds"
                propertiesAvailable="551 properties available"
              />
            </Link>
          </CardBox>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-5">
        <ButtonRed
          name="Add New Top Area"
          className="w-fit ml-auto px-5 py-2"
          link="/area/topArea/editArea"
        />
      </div>
    </>
  );
};

export default TopArea;
