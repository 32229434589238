import React from "react";
import {
  Add,
  ButtonRed,
  Buttons,
  CardBox,
  Loading,
  Title,
} from "../../components";
import { Col, Row } from "../../Grid-system";
import search from "../../images/Icon feather-search.png";
import { useFilter } from "../../APIs/useMyAPI";

const Users = () => {
  const { filter, setFilter } = useFilter();
  return (
    <div>
      <Row className="" justify={"between"}>
        <Col md={4}>
          <Title title="Users" />
        </Col>
        <Col md={6} className={"flex justify-end"}>
          <div className="flex flex-wrap gap-3">
            <div className="border border-Blue flex space-x-2 p-2 rounded-xl">
              <span>
                <img src={search} alt="" />
              </span>
              <input type="search" placeholder="Search" className="" />
            </div>
          </div>
        </Col>
      </Row>
      <Row className={"pt-5"}>
        <Col lg={4} md={6} className="mb-2">
          <CardBox className={"relative"} edit={`/users/id`}>
            <div className="flex justify-start gap-2 mb-2">
              <div className="font-semibold text-Blue">User Name : </div>
              <div>Rama Rama</div>
            </div>
            <div className="flex justify-start gap-2 mb-2">
              <div className="font-semibold text-Blue">E-mail :</div>
              <div>rama@gmail.com</div>
            </div>
            <div className="flex justify-start gap-2 mb-2">
              <div className="font-semibold text-Blue">Phone :</div>
              <div>+963-987654321</div>
            </div>
            <Row className="justify-center pt-3">
              <Col col={6}>
                <ButtonRed
                  className="py-2"
                  link={`/users/Orders-user/${2}`}
                  name="Orders"
                />
              </Col>
            </Row>
          </CardBox>
        </Col>
      </Row>
      {/* <Add>
        <d  iv className="flex flex-col">
          <ButtonRed
            className="py-2 m-1 px-4"
            name="Company"
            link="/users/create-user-Company"
          />

          <ButtonRed
            className="py-2 m-1 px-4"
            name="User"
            link="/users/create-user"
          />
        </d>
      </Add> */}
      {/* <Loading /> */}
    </div>
  );
};

export default Users;
