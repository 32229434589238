import { TextArea, Input } from "../../components";

function EditAboutUsDescription() {
  return (
    <div>
      <Input title="name" />
      <Input title="الاسم" />
      <TextArea title="Description" />
      <TextArea title="الوصف" />
    </div>
  );
}
export default EditAboutUsDescription;
