import { usePOST } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import { AddImage, Back, ButtonRed } from "../../components";

const EditHome = () => {
  const { handleChange, img, setImg, setFormData, formData } = usePOST();
  return (
    <div>
      <Row justify={"center"}>
        <Col md={7} className="">
          <Row justify="center">
            <Col>
              <AddImage
                id="image"
                name="images"
                onChange={handleChange}
                // img={test}
                newimg={img}
                title="add image slider one"
                clickDelete={() => {
                  setImg("");
                  setFormData({ ...formData, images: "" });
                }}
              />
            </Col>
            <Col col={6}>
              <ButtonRed name="Add" className="h-full px-4 py-3" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Back />
    </div>
  );
};

export default EditHome;
