import JoditEditor from "jodit-react";
import { useContextHook } from "../../Context/ContextOPen";
import { useState } from "react";
import { usePOST } from "../../APIs/useMyAPI";
import { useRef } from "react";

function Editor({ onChange }) {
  const test = useRef(null);
  const [content, setContent] = useState("");
  const { formData, handleChange } = usePOST();
  console.log(formData);
  return (
    <div className="my-5">
      <JoditEditor
        ref={test}
        onChange={(newContent) => setContent(newContent)}
      />
      {/* <input
        value={test.current?.value}
        name="text"
        onChange={handleChange}
        // className="hidden"
      /> */}
    </div>
  );
}
export default Editor;
