import { Col, Row } from "../../Grid-system";
import { ButtonRed, CardBox, Title } from "../../components";
import image from "../../images/pubg-battlegrounds-offer-nyz0s.png";
import JobOpenings from "./JobOpenings/JobOpenings";
import OurBenefits from "./OurBenefits/OurBenefits";
import OurPeople from "./OurPeople/OurPeople";
const Careers = () => {
  return (
    <>
      <Title title="Department" />
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox edit={`/Careers/EditCareers`}>
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
            <h2 className="text-center text-3xl text-Blue font-semibold">
              Alhamra
            </h2>
          </CardBox>
        </Col>
      </Row>
      <ButtonRed
        name="Add New department"
        className="w-fit ml-auto px-5 py-2"
        link="/Careers/EditCareers"
      />
      <JobOpenings />
      <OurPeople />
      <OurBenefits />
    </>
  );
};

export default Careers;
