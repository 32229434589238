import { ButtonRed } from "../../components";

function TermsOfUse() {
  return (
    <div>
      <h1 className="text-4xl text-center font-bold my-4">
        TERMS AND CONDITIONS
      </h1>
      <p className="w-[90%] mx-auto">
        Unless you are a real estate or loan professional in your professional
        capacity, you agree to use the Services for your personal use only, and
        your commercial use is limited to transactions done on behalf of
        yourself. Commercial use by real estate or lending professionals is
        limited to providing information to consumers via the Services or taking
        actions on behalf of a client (e.g., listing a home for sale). Among
        other things, lending professionals in lending institutions are
        prohibited from using the information provided by NAWY through the
        Services in making any loan-related decisions. Subject to the
        restrictions set forth below, you may copy information from the Services
        only as necessary for your personal use to view, and/or save, and/or
        print, and/or fax, and/or e-mail such information. You agree otherwise
        not to reproduce, modify, distribute, display or provide access to,
        create derivative works from, decompile or reverse engineer any portion
        of the Services. In addition, you agree not to provide/post/authorize a
        link to any of the Services (including, but not limited to, your agent
        profile page) from a third-party website that is not affiliated with a
        real estate or lending institution owned or operated by NAWY. You may
        display and distribute derivative works from NAWY's data provided that
        NAWY is cited as the source.
      </p>
      <div className="flex justify-end my-3">
        <ButtonRed
          link="/TermsOfUse/editTermsOfUse"
          name="Edit"
          className="px-7 py-2"
        />
      </div>
    </div>
  );
}
export default TermsOfUse