import { BiShow } from "react-icons/bi";
import { Col, Row } from "../../Grid-system";
import {
  AcceptBtn,
  CancelBtn,
  DeleteTable,
  Title,
} from "../../components";
import search from "../../images/MainImageSlider.png";
import { Link } from "react-router-dom";
import { useFilter } from "../../APIs/useMyAPI";
import { MdOutlineSearch } from "react-icons/md";

const OrdersUser = () => {
  const { filter, setFilter } = useFilter({});
  return (
    <div>
      <Col md={2} className="mb-4">
        <Title title="Order-User" />
      </Col>
      <Row className="" justify={"between"}>
        <Col md={2}></Col>
        {filter.get("type") === "history" && (
          <Col
            md={5}
            className={`flex justify-center items-center gap-5 flex-wrap`}
          >
            <MdOutlineSearch size={25} />
            <div className=" border border-Blue p-2 rounded-xl relative flex items-center justify-center">
              <span className="absolute -top-3 bg-white px-2 left-3">Date</span>
              <input type="date" />
            </div>
          </Col>
        )}
      </Row>
      <div className="flex gap-2 ">
        <div className="font-semibold text-boldBlue">User Name : </div>
        <div>Rama Rama</div>
      </div>
      <div className="flex gap-2 ">
        <div className="font-semibold text-boldBlue"> Total income : </div>
        <div>8000$ </div>
      </div>
      <Row>
        <Col>
          <div className=" ">
            <table className="w-full  mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-Boldtext-boldBlue">
              <thead className="bg-Blue text-white h-[70px]">
                <tr>
                  <td>profile photo</td>
                  <td className="max-md:hidden">ID</td>
                  <td>Order status</td>
                  <td className="max-md:hidden">Date</td>
                  <td className="max-md:hidden">Time</td>
                  <td>Price</td>
                  <td>Actoin</td>
                </tr>
              </thead>
              <tbody className="">
                <tr className="border border-Boldtext-boldBlue ">
                  <td>
                    <div className="w-[60px] h-[60px] mx-auto ">
                      <img
                        src={search}
                        alt=""
                        className="w-full h-full rounded-full"
                      />
                    </div>
                  </td>
                  <td className="max-md:hidden">8858632</td>
                  <td className="text-green-600 font-semibold py-7">
                    Completed
                  </td>
                  <td className="max-md:hidden">29/7/2023</td>
                  <td className="max-md:hidden">22:00 AM</td>
                  <td className="text-green-600 font-semibold  ">500$</td>
                  <td className="text-red-600 font-semibold ">
                    <div className="flex h-full justify-center gap-3 items-center">
                      <DeleteTable />
                      <Link to="/Orders/2">
                        <BiShow className="mx-auto cursor-pointer" size={25} />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr className="border border-Boldtext-boldBlue ">
                  <td>
                    <div className="w-[60px] h-[60px] mx-auto ">
                      <img
                        src={search}
                        alt=""
                        className="w-full h-full rounded-full"
                      />
                    </div>
                  </td>
                  <td className="max-md:hidden">8858632</td>
                  <td className="text-blue-600 font-semibold py-7">Waiting</td>
                  <td className="max-md:hidden">29/7/2023</td>
                  <td className="max-md:hidden">22:00 AM</td>
                  <td className="text-green-600 font-semibold  ">500$</td>
                  <td className="text-red-600 font-semibold flex items-center  justify-center">
                    <div className="flex h-full justify-center gap-3 items-center">
                      <div>
                        <CancelBtn />
                        <AcceptBtn />
                      </div>
                      <Link to="/Orders/2">
                        <BiShow className="mx-auto cursor-pointer" size={25} />
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr className="border border-Boldtext-boldBlue ">
                  <td>
                    <div className="w-[60px] h-[60px] mx-auto ">
                      <img
                        src={search}
                        alt=""
                        className="w-full h-full rounded-full"
                      />
                    </div>
                  </td>
                  <td className="max-md:hidden">8858632</td>
                  <td className="text-red-600 font-semibold py-7">
                    Canceled <br />
                    <span className="text-boldBlue">
                      Lorem ipsum dolor sit amet.
                    </span>
                  </td>

                  <td className="max-md:hidden">29/7/2023</td>
                  <td className="max-md:hidden">22:00 AM</td>
                  <td className="text-green-600 font-semibold  ">500$</td>
                  <td className="text-red-600 font-semibold ">
                    <div className="flex h-full justify-center gap-3 items-center">
                      <DeleteTable />
                      <Link to="/Orders/2">
                        <BiShow className="mx-auto cursor-pointer" size={25} />
                      </Link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrdersUser;
