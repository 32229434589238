import { Col, Row } from "../../../Grid-system";
import { ButtonRed, CardBox, Title } from "../../../components";
import image from "../../../images/pubg-battlegrounds-offer-nyz0s.png";
function SliderAndDetails() {
  return (
    <div>
      <Title title="Image Slider" />
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox
            edit={`/compounds/TopCompounds/TopCompoundsDetails/ProductSliderEdit`}
          >
            <img src={image} alt="" className="w-full h-[200px] rounded-3xl" />
          </CardBox>
        </Col>
      </Row>
      <ButtonRed
        name="Add Image"
        className="w-fit ml-auto px-5 py-2 mb-3"
        link="/compounds/TopCompounds/TopCompoundsDetails/ProductSliderEdit"
      />
      <Row className="border border-Blue rounded-2xl my-5 p-2">
        <h2 className="text-3xl text-Blue max-sm:text-2xl font-bold my-6">
          June in North Coast by SODIC
          <span className="text-xl text-gray-500 font-semibold max-sm:text-lg mx-2">
            Compound
          </span>
        </h2>
        <h3 className="text-2xl text-red-600 max-sm:text-2xl font-bold my-6">
          Price : 3,668,000 EGP
          <span className="text-xl text-gray-500 font-semibold max-sm:text-lg mx-2">
            Max Price : 4,668,000 EGP
          </span>
        </h3>
        <h4 className="text-2xl text-Blue max-sm:text-2xl font-bold my-6">
          Monte Galala in Ain Sokhna by Tatweer Misr
        </h4>
        <h5 className="text-2xl max-sm:text-2xl font-bold my-6">Amenities</h5>
        <div className="w-fit flex justify-end my-3">
          <ButtonRed
            link="/compounds/TopCompounds/TopCompoundsDetails/DetailsEdit"
            name="Edit"
            className="px-7 py-2"
          />
        </div>
      </Row>
      <Row>
        <Col lg={3} md={4} xs={6}>
          <CardBox
            edit={`/compounds/topCompounds/topCompoundsDetails/IconCompoundEdit`}
          >
            <div className="flex justify-start items-center text-xl gap-2">
              <div className="flex justify-center items-center w-[150px] h-[150px] bg-gradient-to-l from-[#062371] to-[#E00201] rounded-full">
                <img src={image} alt="" className="rounded-full w-3/4 h-3/4" />
              </div>
              Alhamra
            </div>
          </CardBox>
        </Col>
        <ButtonRed
          name="Add Icon"
          className="w-fit ml-auto px-5 py-2 mb-3"
          link="/compounds/topCompounds/topCompoundsDetails/IconCompoundEdit"
        />
      </Row>
    </div>
  );
}
export default SliderAndDetails;
