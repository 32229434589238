import { ButtonRed, CardBox } from "../../../components";

function RealEstate() {
  return (
    <div>
      <div className="border border-Blue rounded-3xl p-5 my-2">
        <h1 className="text-4xl my-4 font-semibold text-red-600    ">
          Real Estate Egypt Launches
        </h1>
        <p className="text-xl">
          Whether searching for a new home or looking for the next lucrative
          investment opportunity, new launches in the Egyptian real estate
          market are the right choice for you. The market has been booming for
          decades with no signs of slowing down. This is clearly seen in the
          abundance of new launches all over the country. In fact, most real
          estate companies in Egypt have added new projects and compounds to
          their
        </p>
        <h1 className="text-4xl my-4 font-semibold ">
          What are the Advantages of Getting a Home in a Newly Launched
          Compound?
        </h1>
        <h1 className=" ">
          Benefits of buying a property in a new launch in one of the compounds
          include:
        </h1>
        <ul className="pl-5 space-y-4 my-3">
          <li className="list-disc">
            Having access to better prices and deals
          </li>
          <li className="list-disc">Picking the most suitable location</li>
          <li className="list-disc">Being the first to move in</li>
        </ul>
        <h1>
          Usually, real estate companies start by collecting expressions of
          interest (EOI) on the first phase of a project before they start
          selling the properties listed.
        </h1>
      </div>
      <div className= "flex justify-end my-3">
        <ButtonRed
          link="/launches/editRealEstateEdit"
          name="Edit"
          className="px-7 py-2"
        />
      </div>
    </div>
  );
}
export default RealEstate;
